<template>
  <Transition name="slide-fade" appear>
    <div class="px-2 sm:px-6 lg:px-4 text-t3">
      <div class="justify-between flex items-center">
        <h2 class="text-2xl font-bold sm:text-3xl">Reports</h2>
        <!--
				<button class=" font-medium bg-t3 py-2 px-4 text-white rounded-md transition duration-400 hover:text-t3 hover:bg-t3-secondary">
				+ New </button> -->
      </div>
      <div>
        <!-- Start Tabs Component -->
        <app-tabs v-if="tabList.length > 0" :tabList="tabList">
          <template v-slot:Safety>
            <div
              class="text-xl w-full font-semibold text-center text-t3 my-64"
              v-if="loadingReps1"
            >
              <PulseLoader
                :loading="loadingReps1"
                color="#00ADEE"
                size="16px"
                class="mb-6"
              ></PulseLoader>
              <p>Loading. Please Hold.</p>
            </div>
            <div v-if="!loadingReps1" class="flex w-full pt-2 items-center">
              <div
                class="flex justify-between w-full py-4 px-6 gap-4 items-center"
              >
                <button
                  @click="exportSafety()"
                  class="font-medium border-t3 border p-3 text-t3 flex rounded-md hover:border-opacity-0 hover:text-white hover:bg-t3-secondary"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6 mr-2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25M9 16.5v.75m3-3v3M15 12v5.25m-4.5-15H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                    />
                  </svg>

                  Export Spreadsheet
                </button>
                <div class="ml-auto">
                  <span class="mr-4">Date Range :</span>
                  <Datepicker
                    @update:modelValue="updateStats"
                    v-model="dateRangeSN"
                    range
                    :presetRanges="presetRanges"
                  />
                </div>
                <div class="mx-4">
                  <label
                    for="rowscount"
                    class="inline-block mx-2 text-xs 2xl:text-base"
                    >Number of records:</label
                  >
                  <select
                    @change="refreshPage()"
                    v-model="numberOfPages"
                    id="rowscount"
                    class="form-control w-12 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:outline-none"
                  >
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option :value="counts">All</option>
                  </select>
                </div>
                <button
                  @click="firstPage()"
                  v-if="page > 1"
                  class="text-t3 hover:text-t3-teritiary bg-t3-body p-1 rounded-md"
                >
                  <div class="flex">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-6 h-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M18.75 19.5l-7.5-7.5 7.5-7.5m-6 15L5.25 12l7.5-7.5"
                      />
                    </svg>
                  </div>
                </button>
                <button
                  @click="previousPage()"
                  v-if="page > 1"
                  class="text-t3 hover:text-t3-teritiary bg-t3-body p-1 rounded-md"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M15.75 19.5L8.25 12l7.5-7.5"
                    />
                  </svg>
                </button>
                <p
                  class="text-t3 font-medium text-xs 2xl:text-base"
                  v-if="pageCount"
                >
                  Page {{ page }} of {{ pageCount }}
                </p>
                <button
                  @click="nextPage()"
                  v-if="isNext"
                  class="text-t3 hover:text-t3-teritiary bg-t3-body p-1 rounded-md"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M8.25 4.5l7.5 7.5-7.5 7.5"
                    />
                  </svg>
                </button>
                <button
                  @click="lastPage()"
                  v-if="isNext"
                  class="text-t3 hover:text-t3-teritiary bg-t3-body p-1 rounded-md"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M11.25 4.5l7.5 7.5-7.5 7.5m-6-15l7.5 7.5-7.5 7.5"
                    />
                  </svg>
                </button>
              </div>
            </div>
            <div v-if="!loadingReps1" class="overflow-x-auto">
              <table class="w-full" id="safety">
                <thead class="border-b">
                  <tr>
                    <th
                      scope="col"
                      class="text-base font-semibold text-t3 px-3 py-4 text-left w-1/12 text-center"
                    >
                      ID
                    </th>
                    <th
                      scope="col"
                      class="text-base font-semibold text-t3 px-3 py-4 text-left w-3/12 text-center"
                    >
                      Assessor Details
                    </th>
                    <th
                      scope="col"
                      class="text-base font-semibold text-t3 px-3 py-4 text-left text-center"
                    >
                      Pronunciation
                    </th>
                    <th
                      scope="col"
                      class="text-base font-semibold text-t3 px-3 py-4 text-left text-center"
                    >
                      Structure
                    </th>
                    <th
                      scope="col"
                      class="text-base font-semibold text-t3 px-3 py-4 text-left text-center"
                    >
                      Vocabulay
                    </th>
                    <th
                      scope="col"
                      class="text-base font-semibold text-t3 px-3 py-4 text-left text-center"
                    >
                      Fluency
                    </th>
                    <th
                      scope="col"
                      class="text-base font-semibold text-t3 px-3 py-4 text-left text-center"
                    >
                      Comprehension
                    </th>
                    <th
                      scope="col"
                      class="text-base font-semibold text-t3 px-3 py-4 text-left text-center"
                    >
                      Interaction
                    </th>
                    <th
                      scope="col"
                      class="text-base font-semibold text-t3 px-3 py-4 text-left text-center"
                    >
                      Deviation
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="!reports">
                    <div
                      class="text-xl w-full font-semibold text-center text-t3 my-24"
                    >
                      <img
                        src="../assets/no-reports.svg"
                        alt=""
                        class="w-32 m-auto"
                      />
                      <p>
                        It appears you have encountered an error!! It appears
                        there are no reports here yet.
                      </p>
                    </div>
                  </tr>
                  <tr
                    v-for="report in reports"
                    :key="report.id"
                    class="border-b"
                  >
                    <td
                      class="items-center text-blue-700 text-md py-3 text-center"
                      width="15%"
                    >
                      <router-link
                        :to="{
                          name: 'AssessmentDetails',
                          params: { id: report.id },
                        }"
                        >{{ report.id }}</router-link
                      >
                    </td>
                    <td
                      v-if="repAssessor(report.ele)"
                      class="px-3 py-2 whitespace-nowrap text-sm font-medium text-gray-900"
                    >
                      <div class="flex items-center text-md py-3" width="30%">
                        <div class="relative w-10 h-10 mr-3 rounded-full">
                          <img
                            class="object-cover w-full h-full rounded-full"
                            :src="repAssessor(report.ele).profile_img"
                            alt=""
                            loading="lazy"
                          />
                          <div
                            class="absolute inset-0 rounded-full shadow-inner"
                            aria-hidden="true"
                          ></div>
                        </div>
                        <div>
                          <p class="font-semibold">
                            {{ repAssessor(report.ele).full_name }}
                          </p>
                          <p class="text-xs uppercase text-gray-600">
                            {{ repAssessor(report.ele).customer_type }}
                          </p>
                        </div>
                      </div>
                      <div class="flex items-center text-md py-3" width="30%">
                        <div class="relative w-10 h-10 mr-3 rounded-full">
                          <img
                            class="object-cover w-full h-full rounded-full"
                            :src="repAssessor(report.ope).profile_img"
                            alt=""
                            loading="lazy"
                          />
                          <div
                            class="absolute inset-0 rounded-full shadow-inner"
                            aria-hidden="true"
                          ></div>
                        </div>
                        <div>
                          <p class="font-semibold">
                            {{ repAssessor(report.ope).full_name }}
                          </p>
                          <p class="text-xs uppercase text-gray-600">
                            {{ repAssessor(report.ope).customer_type }}
                          </p>
                        </div>
                      </div>
                    </td>
                    <td class="text-md py-3 text-center" width="15%">
                      <p class="my-6">
                        {{
                          report.ele_report.min_descriptor_levels.pronunciation
                        }}
                      </p>
                      <p class="my-6">
                        {{
                          report.ope_report.min_descriptor_levels.pronunciation
                        }}
                      </p>
                    </td>
                    <td class="text-md py-3 text-center" width="15%">
                      <p class="my-6">
                        {{ report.ele_report.min_descriptor_levels.structure }}
                      </p>
                      <p class="my-6">
                        {{ report.ope_report.min_descriptor_levels.structure }}
                      </p>
                    </td>
                    <td class="text-md py-3 text-center" width="15%">
                      <p class="my-6">
                        {{ report.ele_report.min_descriptor_levels.vocabulary }}
                      </p>
                      <p class="my-6">
                        {{ report.ope_report.min_descriptor_levels.vocabulary }}
                      </p>
                    </td>
                    <td class="text-md py-3 text-center" width="15%">
                      <p class="my-6">
                        {{ report.ele_report.min_descriptor_levels.fluency }}
                      </p>
                      <p class="my-6">
                        {{ report.ope_report.min_descriptor_levels.fluency }}
                      </p>
                    </td>
                    <td class="text-md py-3 text-center" width="15%">
                      <p class="my-6">
                        {{
                          report.ele_report.min_descriptor_levels.comprehension
                        }}
                      </p>
                      <p class="my-6">
                        {{
                          report.ope_report.min_descriptor_levels.comprehension
                        }}
                      </p>
                    </td>
                    <td class="text-md py-3 text-center" width="15%">
                      <p class="my-6">
                        {{
                          report.ele_report.min_descriptor_levels.interaction
                        }}
                      </p>
                      <p class="my-6">
                        {{
                          report.ope_report.min_descriptor_levels.interaction
                        }}
                      </p>
                    </td>
                    <td class="text-md py-3 text-center" width="15%">
                      <div
                        class="text-green-500 flex justify-center"
                        v-if="
                          avDev(report.ele_report, report.ope_report) ===
                          '0.00000'
                        "
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-6 h-6"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>
                      </div>
                      <p
                        v-if="
                          avDev(report.ele_report, report.ope_report) !==
                          '0.00000'
                        "
                        class="font-bold text-xl"
                      >
                        ?<br /><span
                          class="text-t3-cancel font-medium text-base mt-2"
                          >{{
                            avDev(report.ele_report, report.ope_report)
                          }}</span
                        >
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div v-if="!loadingReps1" class="overflow-x-auto hidden">
              <table class="w-full" id="safety2">
                <thead class="border-b">
                  <tr>
                    <th
                      scope="col"
                      class="text-base font-semibold text-t3 px-3 py-4 text-left w-1/12 text-center"
                    >
                      ID
                    </th>
                    <th
                      scope="col"
                      class="text-base font-semibold text-t3 px-3 py-4 text-left w-3/12 text-center"
                    >
                      OPE
                    </th>
                    <th
                      scope="col"
                      class="text-base font-semibold text-t3 px-3 py-4 text-left w-3/12 text-center"
                    >
                      ELE
                    </th>
                    <th
                      scope="col"
                      class="text-base font-semibold text-t3 px-3 py-4 text-left text-center"
                    >
                      Pronunciation(OPE)
                    </th>
                    <th
                      scope="col"
                      class="text-base font-semibold text-t3 px-3 py-4 text-left text-center"
                    >
                      Structure(OPE)
                    </th>
                    <th
                      scope="col"
                      class="text-base font-semibold text-t3 px-3 py-4 text-left text-center"
                    >
                      Vocabulay(OPE)
                    </th>
                    <th
                      scope="col"
                      class="text-base font-semibold text-t3 px-3 py-4 text-left text-center"
                    >
                      Fluency(OPE)
                    </th>
                    <th
                      scope="col"
                      class="text-base font-semibold text-t3 px-3 py-4 text-left text-center"
                    >
                      Comprehension(OPE)
                    </th>
                    <th
                      scope="col"
                      class="text-base font-semibold text-t3 px-3 py-4 text-left text-center"
                    >
                      Interaction(OPE)
                    </th>
                    <th
                      scope="col"
                      class="text-base font-semibold text-t3 px-3 py-4 text-left text-center"
                    >
                      Pronunciation(ELE)
                    </th>
                    <th
                      scope="col"
                      class="text-base font-semibold text-t3 px-3 py-4 text-left text-center"
                    >
                      Structure(ELE)
                    </th>
                    <th
                      scope="col"
                      class="text-base font-semibold text-t3 px-3 py-4 text-left text-center"
                    >
                      Vocabulay(ELE)
                    </th>
                    <th
                      scope="col"
                      class="text-base font-semibold text-t3 px-3 py-4 text-left text-center"
                    >
                      Fluency(ELE)
                    </th>
                    <th
                      scope="col"
                      class="text-base font-semibold text-t3 px-3 py-4 text-left text-center"
                    >
                      Comprehension(ELE)
                    </th>
                    <th
                      scope="col"
                      class="text-base font-semibold text-t3 px-3 py-4 text-left text-center"
                    >
                      Interaction(ELE)
                    </th>
                    <th
                      scope="col"
                      class="text-base font-semibold text-t3 px-3 py-4 text-left text-center"
                    >
                      Deviation
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="!reports">
                    <div
                      class="text-xl w-full font-semibold text-center text-t3 my-24"
                    >
                      <img
                        src="../assets/no-reports.svg"
                        alt=""
                        class="w-32 m-auto"
                      />
                      <p>
                        It appears you have encountered an error!! It appears
                        there are no reports here yet.
                      </p>
                    </div>
                  </tr>
                  <tr
                    v-for="report in reports"
                    :key="report.id"
                    class="border-b"
                  >
                    <td
                      class="items-center text-blue-700 text-md py-3 text-center"
                      width="15%"
                    >
                      <router-link
                        :to="{
                          name: 'AssessmentDetails',
                          params: { id: report.id },
                        }"
                        >{{ report.id }}</router-link
                      >
                    </td>
                    <td
                      v-if="repAssessor(report.ele)"
                      class="px-3 py-2 whitespace-nowrap text-sm font-medium text-gray-900"
                    >
                      <p class="font-semibold">
                        {{ repAssessor(report.ope).full_name }}
                      </p>
                    </td>
                    <td
                      v-if="repAssessor(report.ele)"
                      class="px-3 py-2 whitespace-nowrap text-sm font-medium text-gray-900"
                    >
                      <p class="font-semibold">
                        {{ repAssessor(report.ele).full_name }}
                      </p>
                    </td>
                    <td class="text-md py-3 text-center" width="15%">
                      <p class="my-6">
                        {{
                          report.ope_report.min_descriptor_levels.pronunciation
                        }}
                      </p>
                    </td>
                    <td class="text-md py-3 text-center" width="15%">
                      <p class="my-6">
                        {{ report.ope_report.min_descriptor_levels.structure }}
                      </p>
                    </td>
                    <td class="text-md py-3 text-center" width="15%">
                      <p class="my-6">
                        {{ report.ope_report.min_descriptor_levels.vocabulary }}
                      </p>
                    </td>
                    <td class="text-md py-3 text-center" width="15%">
                      <p class="my-6">
                        {{ report.ope_report.min_descriptor_levels.fluency }}
                      </p>
                    </td>
                    <td class="text-md py-3 text-center" width="15%">
                      <p class="my-6">
                        {{
                          report.ope_report.min_descriptor_levels.comprehension
                        }}
                      </p>
                    </td>
                    <td class="text-md py-3 text-center" width="15%">
                      <p class="my-6">
                        {{
                          report.ope_report.min_descriptor_levels.interaction
                        }}
                      </p>
                    </td>
                    <td class="text-md py-3 text-center" width="15%">
                      <p class="my-6">
                        {{
                          report.ele_report.min_descriptor_levels.pronunciation
                        }}
                      </p>
                    </td>
                    <td class="text-md py-3 text-center" width="15%">
                      <p class="my-6">
                        {{ report.ele_report.min_descriptor_levels.structure }}
                      </p>
                    </td>
                    <td class="text-md py-3 text-center" width="15%">
                      <p class="my-6">
                        {{ report.ele_report.min_descriptor_levels.vocabulary }}
                      </p>
                    </td>
                    <td class="text-md py-3 text-center" width="15%">
                      <p class="my-6">
                        {{ report.ele_report.min_descriptor_levels.fluency }}
                      </p>
                    </td>
                    <td class="text-md py-3 text-center" width="15%">
                      <p class="my-6">
                        {{
                          report.ele_report.min_descriptor_levels.comprehension
                        }}
                      </p>
                    </td>
                    <td class="text-md py-3 text-center" width="15%">
                      <p class="my-6">
                        {{
                          report.ele_report.min_descriptor_levels.interaction
                        }}
                      </p>
                    </td>
                    <td class="text-md py-3 text-center" width="15%">
                      <div
                        class="text-green-500 flex justify-center"
                        v-if="
                          avDev(report.ele_report, report.ope_report) ===
                          '0.00000'
                        "
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-6 h-6"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>
                      </div>
                      <p
                        v-if="
                          avDev(report.ele_report, report.ope_report) !==
                          '0.00000'
                        "
                        class="font-bold text-xl"
                      >
                        ?<br /><span
                          class="text-t3-cancel font-medium text-base mt-2"
                          >{{
                            avDev(report.ele_report, report.ope_report)
                          }}</span
                        >
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </template>

          <template v-slot:Customer>
            <div
              class="text-xl w-full font-semibold text-center text-t3 my-64"
              v-if="loadingReps2"
            >
              <PulseLoader
                :loading="loadingReps2"
                color="#00ADEE"
                size="16px"
                class="mb-6"
              ></PulseLoader>
              <p>Loading. Please Hold.</p>
            </div>
            <div v-if="!loadingReps2" class="flex w-full pt-2 items-center">
              <div class="flex justify-end w-full py-4 px-6 gap-4 items-center">
                <button
                  @click="exportReport()"
                  class="font-medium border-t3 border p-3 text-t3 flex rounded-md hover:border-opacity-0 hover:text-white hover:bg-t3-secondary"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6 mr-2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25M9 16.5v.75m3-3v3M15 12v5.25m-4.5-15H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                    />
                  </svg>

                  Export Spreadsheet
                </button>
                <div class="mx-4">
                  <label
                    for="rowscount"
                    class="inline-block mx-2 text-xs 2xl:text-base"
                    >Number of records:</label
                  >
                  <select
                    @change="refreshPageC()"
                    v-model="numberOfPagesC"
                    id="rowscount"
                    class="form-control w-12 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:outline-none"
                  >
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option :value="countsC">All</option>
                  </select>
                </div>
                <button
                  @click="firstPageC()"
                  v-if="pageC > 1"
                  class="text-t3 hover:text-t3-teritiary bg-t3-body p-1 rounded-md"
                >
                  <div class="flex">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-6 h-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M18.75 19.5l-7.5-7.5 7.5-7.5m-6 15L5.25 12l7.5-7.5"
                      />
                    </svg>
                  </div>
                </button>
                <button
                  @click="previousPageC()"
                  v-if="pageC > 1"
                  class="text-t3 hover:text-t3-teritiary bg-t3-body p-1 rounded-md"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M15.75 19.5L8.25 12l7.5-7.5"
                    />
                  </svg>
                </button>
                <p
                  class="text-t3 font-medium text-xs 2xl:text-base"
                  v-if="pageCountC"
                >
                  Page {{ pageC }} of {{ pageCountC }}
                </p>
                <button
                  @click="nextPageC()"
                  v-if="isNextC"
                  class="text-t3 hover:text-t3-teritiary bg-t3-body p-1 rounded-md"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M8.25 4.5l7.5 7.5-7.5 7.5"
                    />
                  </svg>
                </button>
                <button
                  @click="lastPageC()"
                  v-if="isNextC"
                  class="text-t3 hover:text-t3-teritiary bg-t3-body p-1 rounded-md"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M11.25 4.5l7.5 7.5-7.5 7.5m-6-15l7.5 7.5-7.5 7.5"
                    />
                  </svg>
                </button>
              </div>
            </div>
            <div v-if="!loadingReps2" class="overflow-x-auto">
              <table class="w-full" id="GCAA">
                <thead class="border-b">
                  <tr>
                    <th
                      scope="col"
                      class="text-base font-semibold text-t3 px-3 py-4 text-left text-center"
                    >
                      Full Name
                    </th>
                    <th
                      scope="col"
                      class="text-base font-semibold text-t3 px-3 py-4 text-left text-center"
                    >
                      License Type
                    </th>
                    <th
                      scope="col"
                      class="text-base font-semibold text-t3 px-3 py-4 text-left text-center"
                    >
                      License Number
                    </th>
                    <th
                      scope="col"
                      class="text-base font-semibold text-t3 px-3 py-4 text-left text-center"
                    >
                      Country of Issue
                    </th>
                    <th
                      scope="col"
                      class="text-base font-semibold text-t3 px-3 py-4 text-left text-center"
                    >
                      Assessment Date
                    </th>
                    <th
                      scope="col"
                      class="text-base font-semibold text-t3 px-3 py-4 text-left text-center"
                    >
                      ELP Level
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="!customersList">
                    <div
                      class="text-xl w-full font-semibold text-center text-t3 my-24"
                    >
                      <img
                        src="../assets/no-reports.svg"
                        alt=""
                        class="w-32 m-auto"
                      />
                      <p>
                        It appears you have encountered an error!! It appears
                        there are no reports here yet.
                      </p>
                    </div>
                  </tr>
                  <tr
                    v-for="report in customersList"
                    :key="report.id"
                    class="border-b"
                  >
                    <td class="text-md py-3 text-center" width="15%">
                      {{ report.full_name }}
                    </td>
                    <td class="text-md py-3 text-center" width="15%">
                      {{ report.license_type }}
                    </td>
                    <td class="text-md py-3 text-center" width="15%">
                      {{ report.license_number }}
                    </td>

                    <td class="text-md py-3 text-center" width="15%">
                      {{ report.country_of_issue }}
                    </td>
                    <td class="text-md py-3 text-center" width="15%">
                      {{
                        new Date(report.date)
                          .toLocaleDateString("en-GB", {
                            year: "numeric",
                            month: "numeric",
                            day: "numeric",
                          })
                          .split("/")
                          .reverse()
                          .join("-")
                      }}
                    </td>
                    <td class="text-md py-3 text-center" width="15%">
                      {{ report.level }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </template>

          <template v-slot:Finance>
            <div
              class="text-xl w-full font-semibold text-center text-t3 my-64"
              v-if="loadingReps3"
            >
              <PulseLoader
                :loading="loadingReps3"
                color="#00ADEE"
                size="16px"
                class="mb-6"
              ></PulseLoader>
              <p>Loading. Please Hold.</p>
            </div>
            <div v-if="!loadingReps3" class="flex w-full pt-2 items-center">
              <div class="flex justify-end w-full py-4 px-6 gap-4 items-center">
                <button
                  @click="exportFinance()"
                  class="font-medium border-t3 border p-3 text-t3 flex rounded-md hover:border-opacity-0 hover:text-white hover:bg-t3-secondary"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6 mr-2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25M9 16.5v.75m3-3v3M15 12v5.25m-4.5-15H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                    />
                  </svg>

                  Export Spreadsheet
                </button>
                <div class="ml-auto">
                  <span class="mr-4">Date Range :</span>
                  <Datepicker
                    @update:modelValue="updateStatsFN"
                    v-model="dateRangeFN"
                    range
                    :presetRanges="presetRanges"
                  />
                </div>
                <div class="mx-4">
                  <label
                    for="rowscount"
                    class="inline-block mx-2 text-xs 2xl:text-base"
                    >Number of records:</label
                  >
                  <select
                    @change="refreshPageF()"
                    v-model="numberOfPagesF"
                    id="rowscount"
                    class="form-control w-12 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:outline-none"
                  >
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option :value="countsF">All</option>
                  </select>
                </div>
                <button
                  @click="firstPageF()"
                  v-if="pageF > 1"
                  class="text-t3 hover:text-t3-teritiary bg-t3-body p-1 rounded-md"
                >
                  <div class="flex">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-6 h-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M18.75 19.5l-7.5-7.5 7.5-7.5m-6 15L5.25 12l7.5-7.5"
                      />
                    </svg>
                  </div>
                </button>
                <button
                  @click="previousPageF()"
                  v-if="pageF > 1"
                  class="text-t3 hover:text-t3-teritiary bg-t3-body p-1 rounded-md"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M15.75 19.5L8.25 12l7.5-7.5"
                    />
                  </svg>
                </button>
                <p
                  class="text-t3 font-medium text-xs 2xl:text-base"
                  v-if="pageCountF"
                >
                  Page {{ pageF }} of {{ pageCountF }}
                </p>
                <button
                  @click="nextPageF()"
                  v-if="isNextF"
                  class="text-t3 hover:text-t3-teritiary bg-t3-body p-1 rounded-md"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M8.25 4.5l7.5 7.5-7.5 7.5"
                    />
                  </svg>
                </button>
                <button
                  @click="lastPageF()"
                  v-if="isNextF"
                  class="text-t3 hover:text-t3-teritiary bg-t3-body p-1 rounded-md"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M11.25 4.5l7.5 7.5-7.5 7.5m-6-15l7.5 7.5-7.5 7.5"
                    />
                  </svg>
                </button>
              </div>
            </div>
            <div v-if="!loadingReps3" class="overflow-x-auto">
              <table class="w-full" id="finance">
                <thead class="border-b">
                  <tr>
                    <th
                      scope="col"
                      class="text-base font-semibold text-t3 px-3 py-4 text-left text-center"
                    >
                      Customer Name
                    </th>
                    <th
                      scope="col"
                      class="text-base font-semibold text-t3 px-3 py-4 text-left text-center"
                    >
                      Assessment Date
                    </th>
                    <th
                      scope="col"
                      class="text-base font-semibold text-t3 px-3 py-4 text-left text-center"
                    >
                      Organisation
                    </th>
                    <th
                      scope="col"
                      class="text-base font-semibold text-t3 px-3 py-4 text-left text-center"
                    >
                      ELE
                    </th>
                    <th
                      scope="col"
                      class="text-base font-semibold text-t3 px-3 py-4 text-left text-center"
                    >
                      OPE
                    </th>
                    <th
                      scope="col"
                      class="text-base font-semibold text-t3 px-3 py-4 text-left text-center"
                    >
                      Price
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="!finance">
                    <div
                      class="text-xl w-full font-semibold text-center text-t3 my-24"
                    >
                      <img
                        src="../assets/no-reports.svg"
                        alt=""
                        class="w-32 m-auto"
                      />
                      <p>
                        It appears you have encountered an error!! It appears
                        there are no reports here yet.
                      </p>
                    </div>
                  </tr>
                  <tr
                    v-for="report in finance"
                    :key="report.id"
                    class="border-b"
                  >
                    <td class="text-md py-3 text-center" width="15%">
                      {{ report.full_name }}
                    </td>
                    <td class="text-md py-3 text-center" width="15%">
                      {{
                        new Date(report.date).toLocaleDateString("en-GB", {
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                        })
                      }}
                    </td>
                    <td class="text-md py-3 text-center" width="15%">
                      {{ report.organization }}
                    </td>

                    <td class="text-md py-3 text-center" width="15%">
                      {{ report.ele_name }}
                    </td>
                    <td class="text-md py-3 text-center" width="15%">
                      {{ report.ope_name }}
                    </td>
                    <td class="text-md py-3 text-center" width="15%">
                      {{ report.price }} AED
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </template>
        </app-tabs>
        <!-- End Tabs Component -->
      </div>
    </div>
  </Transition>
</template>

<style scoped>
.vue3-easy-data-table[data-v-7314b30a] {
  border: none !important;
}
</style>
<script>
import AppTabs from "@/components/AppTabsReports";
import { mapActions, mapGetters, mapState } from "vuex";
import TableToExcel from "@stanlystark/table-to-excel";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import { ref } from "vue";
import {
  endOfMonth,
  endOfYear,
  startOfMonth,
  startOfYear,
  subMonths,
} from "date-fns";
export default {
  data() {
    return {
      page: 1,
      numberOfPages: 10,
      pageC: 1,
      numberOfPagesC: 10,
      pageF: 1,
      numberOfPagesF: 10,
      emptyState: "",
      searchValue: "",
      loadingReps1: true,
      loadingReps2: true,
      loadingReps3: true,
      tabList: [],
      booking: true,
      dateRangeSN: null,
      dateRangeFN: null,
    };
  },
  setup() {
    const date = ref(new Date());
    const presetRanges = ref([
      {
        label: "Today",
        range: [new Date(), new Date()],
      },
      {
        label: "This month",
        range: [startOfMonth(new Date()), endOfMonth(new Date())],
      },
      {
        label: "Last month",
        range: [
          startOfMonth(subMonths(new Date(), 1)),
          endOfMonth(subMonths(new Date(), 1)),
        ],
      },
      {
        label: "This year",
        range: [startOfYear(new Date()), endOfYear(new Date())],
      },
    ]);

    return {
      date,
      presetRanges,
    };
  },
  methods: {
    ...mapActions({
      getstaff: "Users/getAllStaff",
      getreports: "Assessments/getReports",
      getCustomersList: "Assessments/getCustomersList",
      getFinanceReport: "Assessments/getFinanceReport",
    }),
    updateStats(event) {
      let start = new Date(event[0])
        .toLocaleDateString("en-GB", {
          year: "numeric",
          month: "numeric",
          day: "numeric",
        })
        .split("/")
        .reverse()
        .join("-");
      let end = new Date(event[1])
        .toLocaleDateString("en-GB", {
          year: "numeric",
          month: "numeric",
          day: "numeric",
        })
        .split("/")
        .reverse()
        .join("-");
      if (end === "1970-01-01") {
        end = null;
      }
      this.getreports({
        id: this.page,
        number: this.numberOfPages,
        startDate: start,
        endDate: end,
      }).then(() => {
        this.loadingReps1 = false;
      });
    },
    nextPage() {
      let start,
        end = null;
      if (this.dateRangeSN) {
        start = new Date(this.dateRangeSN[0])
          .toLocaleDateString("en-GB", {
            year: "numeric",
            month: "numeric",
            day: "numeric",
          })
          .split("/")
          .reverse()
          .join("-");
        end = new Date(this.dateRangeSN[1])
          .toLocaleDateString("en-GB", {
            year: "numeric",
            month: "numeric",
            day: "numeric",
          })
          .split("/")
          .reverse()
          .join("-");
        if (end === "1970-01-01") {
          end = null;
        }
      }
      this.loadingReps1 = true;
      this.page++;
      this.getreports({
        id: this.page,
        number: this.numberOfPages,
        startDate: start,
        endDate: end,
      }).then(() => {
        this.loadingReps1 = false;
      });
    },
    lastPage() {
      let start,
        end = null;
      if (this.dateRangeSN) {
        start = new Date(this.dateRangeSN[0])
          .toLocaleDateString("en-GB", {
            year: "numeric",
            month: "numeric",
            day: "numeric",
          })
          .split("/")
          .reverse()
          .join("-");
        end = new Date(this.dateRangeSN[1])
          .toLocaleDateString("en-GB", {
            year: "numeric",
            month: "numeric",
            day: "numeric",
          })
          .split("/")
          .reverse()
          .join("-");
        if (end === "1970-01-01") {
          end = null;
        }
      }

      this.loadingReps1 = true;
      this.page = this.pageCount;
      this.getreports({
        id: this.page,
        number: this.numberOfPages,
        startDate: start,
        endDate: end,
      }).then(() => {
        this.loadingReps1 = false;
      });
    },
    previousPage() {
      let start,
        end = null;
      if (this.dateRangeSN) {
        start = new Date(this.dateRangeSN[0])
          .toLocaleDateString("en-GB", {
            year: "numeric",
            month: "numeric",
            day: "numeric",
          })
          .split("/")
          .reverse()
          .join("-");
        end = new Date(this.dateRangeSN[1])
          .toLocaleDateString("en-GB", {
            year: "numeric",
            month: "numeric",
            day: "numeric",
          })
          .split("/")
          .reverse()
          .join("-");
        if (end === "1970-01-01") {
          end = null;
        }
      }
      this.loadingReps1 = true;
      this.page--;
      this.getreports({
        id: this.page,
        number: this.numberOfPages,
        startDate: start,
        endDate: end,
      }).then(() => {
        this.loadingReps1 = false;
      });
    },
    firstPage() {
      let start,
        end = null;
      if (this.dateRangeSN) {
        start = new Date(this.dateRangeSN[0])
          .toLocaleDateString("en-GB", {
            year: "numeric",
            month: "numeric",
            day: "numeric",
          })
          .split("/")
          .reverse()
          .join("-");
        end = new Date(this.dateRangeSN[1])
          .toLocaleDateString("en-GB", {
            year: "numeric",
            month: "numeric",
            day: "numeric",
          })
          .split("/")
          .reverse()
          .join("-");
        if (end === "1970-01-01") {
          end = null;
        }
      }
      this.loadingReps1 = true;
      this.page = 1;
      this.getreports({
        id: this.page,
        number: this.numberOfPages,
        startDate: start,
        endDate: end,
      }).then(() => {
        this.loadingReps1 = false;
      });
    },
    refreshPage() {
      let start,
        end = null;
      if (this.dateRangeSN) {
        start = new Date(this.dateRangeSN[0])
          .toLocaleDateString("en-GB", {
            year: "numeric",
            month: "numeric",
            day: "numeric",
          })
          .split("/")
          .reverse()
          .join("-");
        end = new Date(this.dateRangeSN[1])
          .toLocaleDateString("en-GB", {
            year: "numeric",
            month: "numeric",
            day: "numeric",
          })
          .split("/")
          .reverse()
          .join("-");
        if (end === "1970-01-01") {
          end = null;
        }
      }
      this.loadingReps1 = true;
      this.page = 1;
      this.getreports({
        id: this.page,
        number: this.numberOfPages,
        startDate: start,
        endDate: end,
      }).then(() => {
        this.loadingReps1 = false;
      });
    },

    // Customer List Calls
    nextPageC() {
      this.loadingReps2 = true;
      this.pageC++;
      this.getCustomersList({
        id: this.pageC,
        number: this.numberOfPagesC,
      }).then(() => {
        this.loadingReps2 = false;
      });
    },
    lastPageC() {
      this.loadingReps2 = true;
      this.pageC = this.pageCCount;
      this.getCustomersList({
        id: this.pageC,
        number: this.numberOfPagesC,
      }).then(() => {
        this.loadingReps2 = false;
      });
    },
    previousPageC() {
      this.loadingReps2 = true;
      this.pageC--;
      this.getCustomersList({
        id: this.pageC,
        number: this.numberOfPagesC,
      }).then(() => {
        this.loadingReps2 = false;
      });
    },
    firstPageC() {
      this.loadingReps2 = true;
      this.pageC = 1;
      this.getCustomersList({
        id: this.pageC,
        number: this.numberOfPagesC,
      }).then(() => {
        this.loadingReps2 = false;
      });
    },
    refreshPageC() {
      this.loadingReps2 = true;
      this.pageC = 1;
      this.getCustomersList({
        id: this.pageC,
        number: this.numberOfPagesC,
      }).then(() => {
        this.loadingReps2 = false;
      });
    },

    // Finance Calls
    updateStatsFN(event) {
      let start = new Date(event[0])
        .toLocaleDateString("en-GB", {
          year: "numeric",
          month: "numeric",
          day: "numeric",
        })
        .split("/")
        .reverse()
        .join("-");
      let end = new Date(event[1])
        .toLocaleDateString("en-GB", {
          year: "numeric",
          month: "numeric",
          day: "numeric",
        })
        .split("/")
        .reverse()
        .join("-");
      if (end === "1970-01-01") {
        end = null;
      }
      this.getFinanceReport({
        id: this.pageF,
        number: this.numberOfPagesF,
        startDate: start,
        endDate: end,
      }).then(() => {
        this.loadingReps3 = false;
      });
    },
    nextPageF() {
      this.loadingReps3 = true;
      this.pageF++;
      let start,
        end = null;
      if (this.dateRangeFN) {
        start = new Date(this.dateRangeFN[0])
          .toLocaleDateString("en-GB", {
            year: "numeric",
            month: "numeric",
            day: "numeric",
          })
          .split("/")
          .reverse()
          .join("-");
        end = new Date(this.dateRangeFN[1])
          .toLocaleDateString("en-GB", {
            year: "numeric",
            month: "numeric",
            day: "numeric",
          })
          .split("/")
          .reverse()
          .join("-");
        if (end === "1970-01-01") {
          end = null;
        }
      }
      this.getFinanceReport({
        id: this.pageF,
        number: this.numberOfPagesF,
        startDate: start,
        endDate: end,
      }).then(() => {
        this.loadingReps3 = false;
      });
    },
    lastPageF() {
      this.loadingReps3 = true;
      this.pageF = this.pageFCount;
      let start,
        end = null;
      if (this.dateRangeFN) {
        start = new Date(this.dateRangeFN[0])
          .toLocaleDateString("en-GB", {
            year: "numeric",
            month: "numeric",
            day: "numeric",
          })
          .split("/")
          .reverse()
          .join("-");
        end = new Date(this.dateRangeFN[1])
          .toLocaleDateString("en-GB", {
            year: "numeric",
            month: "numeric",
            day: "numeric",
          })
          .split("/")
          .reverse()
          .join("-");
        if (end === "1970-01-01") {
          end = null;
        }
      }
      this.getFinanceReport({
        id: this.pageF,
        number: this.numberOfPagesF,
        startDate: start,
        endDate: end,
      }).then(() => {
        this.loadingReps3 = false;
      });
    },
    previousPageF() {
      this.loadingReps3 = true;
      this.pageF--;
      let start,
        end = null;
      if (this.dateRangeFN) {
        start = new Date(this.dateRangeFN[0])
          .toLocaleDateString("en-GB", {
            year: "numeric",
            month: "numeric",
            day: "numeric",
          })
          .split("/")
          .reverse()
          .join("-");
        end = new Date(this.dateRangeFN[1])
          .toLocaleDateString("en-GB", {
            year: "numeric",
            month: "numeric",
            day: "numeric",
          })
          .split("/")
          .reverse()
          .join("-");
        if (end === "1970-01-01") {
          end = null;
        }
      }
      this.getFinanceReport({
        id: this.pageF,
        number: this.numberOfPagesF,
        startDate: start,
        endDate: end,
      }).then(() => {
        this.loadingReps3 = false;
      });
    },
    firstPageF() {
      this.loadingReps3 = true;
      this.pageF = 1;
      let start,
        end = null;
      if (this.dateRangeFN) {
        start = new Date(this.dateRangeFN[0])
          .toLocaleDateString("en-GB", {
            year: "numeric",
            month: "numeric",
            day: "numeric",
          })
          .split("/")
          .reverse()
          .join("-");
        end = new Date(this.dateRangeFN[1])
          .toLocaleDateString("en-GB", {
            year: "numeric",
            month: "numeric",
            day: "numeric",
          })
          .split("/")
          .reverse()
          .join("-");
        if (end === "1970-01-01") {
          end = null;
        }
      }
      this.getFinanceReport({
        id: this.pageF,
        number: this.numberOfPagesF,
        startDate: start,
        endDate: end,
      }).then(() => {
        this.loadingReps3 = false;
      });
    },
    refreshPageF() {
      this.loadingReps3 = true;
      this.pageF = 1;
      let start,
        end = null;
      if (this.dateRangeFN) {
        start = new Date(this.dateRangeFN[0])
          .toLocaleDateString("en-GB", {
            year: "numeric",
            month: "numeric",
            day: "numeric",
          })
          .split("/")
          .reverse()
          .join("-");
        end = new Date(this.dateRangeFN[1])
          .toLocaleDateString("en-GB", {
            year: "numeric",
            month: "numeric",
            day: "numeric",
          })
          .split("/")
          .reverse()
          .join("-");
        if (end === "1970-01-01") {
          end = null;
        }
      }
      this.getFinanceReport({
        id: this.pageF,
        number: this.numberOfPagesF,
        startDate: start,
        endDate: end,
      }).then(() => {
        this.loadingReps3 = false;
      });
    },
    minimum(tasks, descriptor) {
      let temp = [];
      tasks.forEach((t) => {
        temp.push(t[descriptor]);
      });
      return Math.min(...temp);
    },
    populateTabList() {
      let tabs = [];
      if (this.scopeCheck("view_safety_net")) {
        tabs.push("Safety Net");
      }
      if (this.scopeCheck("view_customer_list")) {
        tabs.push("Customer List");
      }
      if (this.scopeCheck("view_finance_report")) {
        tabs.push("Finance Report");
      }
      this.tabList = tabs;
    },
    getIndex(string) {
      return this.tabList.indexOf(string);
    },
    exportSafety() {
      var dates = new Date()
        .toLocaleDateString("en-GB", {
          year: "numeric",
          month: "numeric",
          day: "numeric",
        })
        .split("/")
        .reverse()
        .join("-");
      TableToExcel.convert(document.getElementById("safety2"), {
        name: "SafetyReport-" + dates + ".xlsx",
        sheet: {
          name: "Sheet 1",
        },
      });
    },
    exportReport() {
      var dates = new Date()
        .toLocaleDateString("en-GB", {
          year: "numeric",
          month: "numeric",
          day: "numeric",
        })
        .split("/")
        .reverse()
        .join("-");
      TableToExcel.convert(document.getElementById("GCAA"), {
        name: "CustomerList-" + dates + ".xlsx",
        sheet: {
          name: "Sheet 1",
        },
      });
    },
    exportFinance() {
      var dates = new Date()
        .toLocaleDateString("en-GB", {
          year: "numeric",
          month: "numeric",
          day: "numeric",
        })
        .split("/")
        .reverse()
        .join("-");
      TableToExcel.convert(document.getElementById("finance"), {
        name: "Finance Report-" + dates + ".xlsx",
        sheet: {
          name: "Sheet 1",
        },
      });
    },
    avDev(reportE, reportO) {
      let numbers = [
        [
          reportE.min_descriptor_levels.pronunciation,
          reportO.min_descriptor_levels.pronunciation,
        ],
        [
          reportE.min_descriptor_levels.structure,
          reportO.min_descriptor_levels.structure,
        ],
        [
          reportE.min_descriptor_levels.vocabulary,
          reportO.min_descriptor_levels.vocabulary,
        ],
        [
          reportE.min_descriptor_levels.fluency,
          reportO.min_descriptor_levels.fluency,
        ],
        [
          reportE.min_descriptor_levels.comprehension,
          reportO.min_descriptor_levels.comprehension,
        ],
        [
          reportE.min_descriptor_levels.interaction,
          reportO.min_descriptor_levels.interaction,
        ],
      ];
      let newnumbers = numbers.map((el) => {
        let avg = (el[0] + el[1]) / 2;
        return Math.abs(avg - el[0]);
      });
      let notR = this.sumUp(newnumbers) / newnumbers.length;
      return notR.toFixed(5);
    },
    sumUp(arr) {
      let sum = 0;
      arr.forEach((el) => {
        sum += el;
      });
      return sum;
    },
    repAssessor(assessor) {
      return this.staffOnly.find((s) => s.id === assessor);
    },
    scopeCheck(scope) {
      let user = JSON.parse(localStorage.getItem("userdetail"));

      if (user.is_company) {
        return true;
      } else {
        return localStorage.getItem("scopes").includes(scope);
      }
    },
  },
  components: {
    AppTabs,
    PulseLoader,
    Datepicker,
  },

  computed: {
    ...mapState({
      reports: (state) => state.Assessments.reports,
      customersList: (state) => state.Assessments.customersList,
      finance: (state) => state.Assessments.finance,
      staffOnly: (state) => state.Users.allStaff,
      isNext: (state) => state.Assessments.nextReport,
      counts: (state) => state.Assessments.countReport,
      isNextC: (state) => state.Assessments.nextC,
      countsC: (state) => state.Assessments.countC,
      isNextF: (state) => state.Assessments.nextF,
      countsF: (state) => state.Assessments.countF,
    }),
    ...mapGetters("Users", ["customerOnly", "allOPE", "allELE"]),
  },

  created() {
    this.populateTabList();
    this.getstaff();
    this.getreports({ id: this.page, number: this.numberOfPages }).then(() => {
      this.loadingReps1 = false;
    });
    this.getCustomersList({ id: this.pageC, number: this.numberOfPagesC }).then(
      () => {
        this.loadingReps2 = false;
      }
    );
    this.getFinanceReport({ id: this.pageF, number: this.numberOfPagesF }).then(
      () => {
        this.loadingReps3 = false;
      }
    );
  },

  mounted() {

  },
};
</script>
